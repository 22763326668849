import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import {
  Container,
  Row,
  Col,
  CardBody,
  Label,
  NavItem,
  NavLink
} from 'reactstrap'
import styled from 'styled-components'

import API from '../utils/API'
import { parseOrders, OrdersRow } from 'utils/ordersHandler'

import OrdersTable from '../components/OrdersTable'
import SideMenu from '../components/SideMenu'
import SearchForm from '../components/SearchForm'

import { Column } from 'models/table.model'
import { RootState } from 'models/redux.model'
import socket from 'utils/socket'

import SelectPharma from '../components/SelectPharma'
import { useIntl } from 'react-intl'

import InfosViewDisabledModal from 'components/InfosViewDisabledModal'

import { faSyncAlt, faFilter } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Helmet from 'react-helmet'
import { LoaderWrapper } from 'assets/styles/LoaderWrapper'
import { OrdersWrapper } from 'assets/styles/OrdersWrapper'
import { CardWrapper } from 'assets/styles/CardWrapper'
import { ContainerWrapper } from 'assets/styles/ContainerWrapper'
import { applySort } from 'utils/sortHandler'

import FilterModal from 'components/Filter/FilterModal'
import { useLocation } from 'react-router'
import NoResultFound from 'components/Filter/NoResultFound'
import moment, { Moment } from 'moment'
import { parseInt } from 'lodash'
import { history } from 'store'

const loadingIcon = 'images/loading-gif-phacil.svg'
const blurOrder = 'images/blurOrder.png'

const Orders = (props: any): JSX.Element => {
  // Pharmacy list from Redux
  const pharmacies = useSelector((state: RootState) => state.user.pharmacies)
  const selectedPharma = useSelector(
    (state: RootState) => state.user.selectedPharma
  )
  const { formatMessage, locale } = useIntl()
  const f = (id: any): string => formatMessage({ id })
  const [data, setData] = useState<OrdersRow[]>([]) // Array of examples
  const [filteredRows, setFilteredRows] = useState<OrdersRow[]>([]) // Array of examples
  const [currentSearchValue, setCurSearchValue] = useState<string>('')
  const [sortCriteria, setSortCriteria] = useState<{
    direction: 'DESC' | 'ASC'
    accessor: string
  } | null>({
    direction: 'DESC',
    accessor: 'dateOrder'
  })

  const pageSize = 50
  const [isLoading, setIsLoading] = useState(true)

  const [filter, setFilter] = useState<number>(0) // [Validée, En attente, Annulée]

  const motifsByLanguage = [f('all'), f('confirmed'), f('waiting'), f('canceled')]

  const [activeTab, setActiveTab] = useState('0')

  const [openModalDisabled, setOpenModalDisabled] = useState(true)
  const toggle = (): void => setOpenModalDisabled(!openModalDisabled)

  const [isOpenFilter, setIsOpenFilter] = useState(false)
  const toggleFilter = (): void => setIsOpenFilter(!isOpenFilter)

  const [openDate, setOpenDate] = useState(true)

  const [typeDate, setTypeDate] = useState<number>(0)
  const [afterDate, setAfterDate] = useState<Moment>(moment())
  const [startDate, setStartDate] = useState<Moment>(moment())
  const [endDate, setEndDate] = useState<Moment>(moment())
  const [equalDate, setEqualDate] = useState<Moment>(moment())
  const [beforeDate, setBeforeDate] = useState<Moment>(moment())

  const search = useLocation().search

  const isMultipharmaAccount = useSelector((state: RootState) => state.user.isMultipharmaAccount)

  // Configuration for the Orders table

  const columns: Column[] = [
    {
      Header: f('nbPhacilOrder'),
      // TODO PLACEHOLDER 1234 instead of order_id
      accessor: 'orderId',
      filtrable: true
    },
    {
      Header: f('status'),
      accessor: 'state',
      filtrable: true
    },
    {
      Header: f('orderType2'),
      // TODO PLACEHOLDER 1234 instead of order_id
      accessor: 'type',
      filtrable: true
    },
    {
      Header: f('orderDate'),
      accessor: 'date',
      sortable: true,
      sortAccessor: 'dateOrder'  // Use dateOrder for sorting but display date
    },
    {
      Header: f('price2'),
      accessor: 'costsDetails'
    },
    {
      Header: f('deliveryType'),
      accessor: 'deliveryMode'
    },
    {
      Header: f('client2'),
      accessor: 'customerName'
    },
    {
      Header: f('monitoringNb'),
      accessor: 'tracking_code'
    }
    // {
    //     Header: 'Documents',
    //     accessor: 'file',
    // }
  ]

  useEffect(() => {
    if (selectedPharma.pharmacy_id !== -1) {
      socket.on('receive_id', (id: number) => {
        if (id === selectedPharma.pharmacy_id) getData(selectedPharma.pharmacy_id).catch(err => console.log(err))
      })
    }
  }, [selectedPharma])

  /**
   * Filters the rows according to the current search value (filter on Name or EAN code)
   */
  const applyFilters = (): void => {
    let newData = data
    const searchParams = new URLSearchParams(window.location.search)

    if (filter !== 0) {
      searchParams.set('status', filter.toString())
      history.replace({ pathname: '/orders', search: searchParams.toString() })

      newData = data.filter(order => {
        let status = -1

        if (order.state > 1 && order.state < 6) status = 1
        else if (order.state <= 1) status = 2
        else if (order.state >= 6) status = 3

        return status === filter
      })
    } else {
      searchParams.delete('status')
      history.replace({ search: searchParams.toString() })
    }

    if (currentSearchValue == null || currentSearchValue === '') {
      return setFilteredRows([...newData])
    }

    const newFilteredRows = newData.filter(cart => {
      if (cart?.customerName.toUpperCase().includes(currentSearchValue.toUpperCase())) return true

      if (cart.products != null) {
        for (let i = 0; i < cart.products.length; i++) {
          if (cart?.products?.[i]?.EAN_code?.toString()
            .toUpperCase()
            .includes(currentSearchValue.toUpperCase())
          ) { return true }
        }
      }

      return false
    })
    setFilteredRows([...newFilteredRows])
  }

  useEffect(() => {
    const searchStart = new URLSearchParams(search).get('start')
    const searchEnd = new URLSearchParams(search).get('end')
    const searchStatus = new URLSearchParams(search).get('status')

    if (searchStatus == null) {
      getData(selectedPharma.pharmacy_id, searchStart, searchEnd).catch((err) => { console.log(err) })
    }
  }, [search])

  /** CRUD FUNCTIONS */
  const getData = async (pharmaId: number, start?: string | null, end?: string | null): Promise<void> => {
    setIsLoading(true)

    const timestampStart = new URLSearchParams(search).get('start')
    const timestampEnd = new URLSearchParams(search).get('end')

    try {
      if (pharmaId == null && selectedPharma != null) {
        pharmaId = selectedPharma.pharmacy_id
      }

      if (pharmaId === -1) return

      let url = `/pharmacist/pharmacy-orders/${pharmaId}`

      if (start != null || end != null || timestampStart != null || timestampEnd != null) {
        url = `/pharmacist/pharmacy-orders/${pharmaId}?start=${start || timestampStart}&end=${end || timestampEnd}`
      }

      const examplesResponse = await API.get(url)

      let orders = parseOrders(
        examplesResponse.data.responsePara,
        examplesResponse.data.responsePrescription,
        examplesResponse.data.responseParaAndPrescription,
        locale
      )

      // Sort by timestamp (date of creation) instead of orderId
      orders = orders.sort((orderA, orderB) => {
        // Compare the moment objects directly for date sorting
        return orderB.dateOrder.valueOf() - orderA.dateOrder.valueOf()
      })
      setData([...orders])
      setIsLoading(false)
    } catch (e: any) {
      console.log(e)
    }
  }

  /**
   * OTHER EVENTS HANDLERS
   */
  useEffect(() => {
    if (selectedPharma != null) {
      socket.emit('join_room', `admin_pharma_${selectedPharma.pharmacy_id}`)
      getData(selectedPharma.pharmacy_id).catch(err => console.log(err))
      setIsOpenFilter(false)
    }
  }, [selectedPharma])

  useEffect(() => {
    applyFilters()
  }, [currentSearchValue, data, filter])

  useEffect(() => {
    applySort(data, setData, sortCriteria)
  }, [sortCriteria])

  useEffect(() => {
    if (isLoading) {
      if (data.length > 0) {
        setData(prevState => {
          return prevState.sort((a: any, b: any) => {
            // Sort by date of creation (timestamp) instead of order_id
            return b.dateOrder.valueOf() - a.dateOrder.valueOf()
          })
        })
        setIsLoading(false)
      }
    }
  }, [data])

  // useEffect(() => {
  //   if (!isLoading) setFilteredRows([...data])
  // }, [isLoading])

  useEffect(() => {
    const start = new URLSearchParams(search).get('start')
    const end = new URLSearchParams(search).get('end')
    const status = new URLSearchParams(search).get('status')

    if ((start != null && end == null)) {
      setAfterDate(moment(parseInt(start) * 1000))
      setTypeDate(2)
    } else if ((start == null && end != null)) {
      setBeforeDate(moment(parseInt(end) * 1000))
      setTypeDate(3)
    } else if ((start != null && end != null)) {
      const tmp = moment(new Date(parseInt(start) * 1000)).format('YYYY-MM-DD')
      const tmpEnd = moment(new Date(parseInt(end) * 1000)).format('YYYY-MM-DD')
      if (moment(tmp).isSame(tmpEnd)) {
        setTypeDate(1)
        setEqualDate(moment(parseInt(start) * 1000))
      } else {
        setTypeDate(0)
        setStartDate(moment(parseInt(start) * 1000))
        setEndDate(moment(parseInt(end) * 1000))
      }
    }

    if (status != null) {
      setFilter(parseInt(status))
      setActiveTab(status)
    }
  }, [selectedPharma])

  return (
    <OrdersWrapper property='order' className='dataContainer'>
      <Helmet>
        <title>{f('ordersMetaTitle')}</title>
        <link rel='canonical' href='https://www.phacil.app/' />
        <meta name='description' content={f('ordersMetaDescription')} />
        <link rel='alternate' hrefLang='fr' href='https://www.phacil.app/' />
      </Helmet>
      <SideMenu parent='Orders' />
      <ContainerWrapper>
        <Container fluid className={(selectedPharma.pharmacy.pharmacy_menu?.orders === 1 && !isMultipharmaAccount) ? 'blur' : ''}>
          {
            (selectedPharma.pharmacy.pharmacy_menu?.orders === 1 && !isMultipharmaAccount)
              ? <img src={blurOrder} alt='' />
              : (
                <>
                  <Row className='headerTitle'>
                    <h2>{f('orders')}</h2>
                    {selectedPharma && (
                      <SelectPharma pharmacies={pharmacies} />
                    )}
                    <StyledFontAwesomeIcon
                      icon={faSyncAlt}
                      onClick={() => {
                        setIsLoading(true)

                        if (search === '') getData(selectedPharma.pharmacy_id).catch(err => console.log(err))
                        else history.push({ pathname: '/orders' })
                      }}
                    />
                  </Row>
                  <Row>
                    <Col xs='12'>
                      <CardWrapper order>
                        {isLoading ? (
                          <LoaderWrapper>
                            <h5>{f('loadingOrders')}</h5>
                            <img src={loadingIcon} alt='loading' width='35' />
                          </LoaderWrapper>
                        ) : (
                          <CardBody>
                            <FiltersAboveTable>
                              <BorderBottomCol className='nav' lg='12'>
                                {motifsByLanguage.map((motifByLanguage: string, index: number) => (
                                  <NavItem key={index}>
                                    <NavLink
                                      className={activeTab === index.toString() ? 'active' : ''}
                                      style={{ cursor: 'pointer' }}
                                      onClick={() => {
                                        setFilter(index)
                                        setActiveTab(index.toString())
                                      }}
                                    >
                                      {motifByLanguage}
                                    </NavLink>
                                  </NavItem>
                                ))}

                                <SearchbarWrapper>
                                  <button
                                    id='popoverFilter' type='submit' className='btn btnFilter mr-2'
                                    onClick={() => { setOpenDate(true) }}
                                  >
                                    <FontAwesomeIcon
                                      style={{
                                        cursor: 'pointer',
                                        marginRight: '5px'
                                      }}
                                      icon={faFilter} size='xs'

                                    />

                                    {f('filter')}
                                  </button>
                                  {f('search')}
                                  <SearchForm
                                    onSearch={applyFilters}
                                    setInputValue={newValue =>
                                      setCurSearchValue(newValue)}
                                    inputValue={currentSearchValue}
                                  />
                                </SearchbarWrapper>
                              </BorderBottomCol>
                            </FiltersAboveTable>
                            <FilterModal
                              isOpenFilter={isOpenFilter}
                              openDate={openDate}
                              setOpenDate={setOpenDate}
                              setIsOpenFilter={setIsOpenFilter}
                              toggleFilter={toggleFilter}
                              typeDate={typeDate}
                              setTypeDate={setTypeDate}
                              afterDate={afterDate}
                              setAfterDate={setAfterDate}
                              startDate={startDate}
                              setStartDate={setStartDate}
                              endDate={endDate}
                              setEndDate={setEndDate}
                              equalDate={equalDate}
                              setEqualDate={setEqualDate}
                              beforeDate={beforeDate}
                              setBeforeDate={setBeforeDate}

                            />
                            {data.length > 0
                              ? (
                                <OrdersTable
                                  sortCriteria={sortCriteria}
                                  setSortCriteria={setSortCriteria}
                                  columns={columns}
                                  data={filteredRows}
                                  pageSize={pageSize}
                                  searchValue={currentSearchValue}
                                  getData={getData}
                                />
                              ) : <NoResultFound />}

                          </CardBody>
                        )}
                      </CardWrapper>
                    </Col>
                  </Row>
                </>
              )
          }

        </Container>
      </ContainerWrapper>
      {
        selectedPharma.pharmacy.pharmacy_menu?.orders === 1 && !isMultipharmaAccount &&
        <InfosViewDisabledModal
          isOpen={openModalDisabled}
          toggle={toggle}
        />
      }
    </OrdersWrapper>
  )
}

const FiltersAboveTable = styled(Row)`
  color: var(--texte-table);
  font-weight: 700;
  font-size: 0.9rem;

  label select {
    margin: 0 0.5rem;
  }
  label form {
    margin-left: 0.5rem;
  }
  .nav {
    margin-left: 1rem;
  }
`
const SearchbarWrapper = styled(Label)`
  display: flex;
  justify-content: flex-end;
  flex: auto;
  margin-bottom: unset;
  input {
    margin-right: 0;
  }
`
const BorderBottomCol = styled(Col)`
  border-bottom: 1px solid #dee2e6;
  padding: 0;
  max-width: 97%;
  a:hover,
  span:hover {
    filter: brightness(80%);
  }
`
const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  margin-top: 8px;
  margin-left: 10px;
  cursor: pointer;
`

export default Orders
