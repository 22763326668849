import React, { useEffect, useState, Dispatch, SetStateAction } from 'react'
import { Modal, ModalBody, Row, Col, FormGroup, Label, Input, Card, Collapse } from 'reactstrap'
import styled from 'styled-components'
import moment from 'moment'
import 'moment/locale/fr'
import 'moment/locale/es'
import API from '../../utils/API'
import { MessageState } from 'models/message.model'

import ModalCarousel from './ModalComponents/ModalCarousel'
import ModalProduits from './ModalComponents/ModalProduits'
import ModalOrdonnance from './ModalComponents/ModalOrdonnance'
import ModalOrdonnancePrice from './ModalComponents/ModalOrdonnancePrice'
import SidebarOrdonnance from './ModalComponents/SidebarOrdonnance'
import { alertSuccess, alertError } from '../../utils/notifications'
import ValidationModal from './ValidationModal'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faRedoAlt, faPlus, faMinus, faExclamationCircle, faInfo } from '@fortawesome/free-solid-svg-icons'
import { sendReloadInfo } from 'utils/socket'
import Chat from '../Chat/Chat'
import CommentaryChat from '../Chat/CommentaryChat'
import DownloadTntFile from './ModalComponents/DownloadTntFile'

import CustomButton from '../ui/Button'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { RootState } from 'models/redux.model'
import AskTntFile from './ModalComponents/AskTntFile'

interface OrderModalProps {
  // data: Order | undefined;
  data: any
  open: boolean
  // selectedRow: number,
  onClose: () => any
  getData: any
  setSelectOrderId: Dispatch<SetStateAction<number>>
  displayFaCircle: Dispatch<SetStateAction<void>>
  setIconCircle: Dispatch<SetStateAction<string>>
  filteredRows: any
}

const OrderModal = (props: OrderModalProps): JSX.Element => {
  const { formatMessage, locale } = useIntl()
  const f = (id: any): string => formatMessage({ id })

  locale === 'es' && moment.locale('es')

  const [initialCommentary, setInitialCommentary] = useState<string>(
    props.data.commentaryPharmacist
  )
  const [newCommentary, setNewCommentary] = useState<string>('')

  const [initialStatut, setInitialStatut] = useState(props.data.state)
  const [selectedStatut, setSelectedStatut] = useState<number>(initialStatut)

  const [resultChgmtStatut, setResultChgmtStatut] = useState<string>('')

  const [cancelInfo, setCancelInfo] = useState<string>('')
  const [customCancelInfo, setCustomCancelInfo] = useState<boolean>(false)

  const [initialPrice, setInitialPrice] = useState<number>(props.data.costsDetails)
  const [newPrice, setNewPrice] = useState<number>(initialPrice)

  const initialSuivi = props.data.tracking_code
  const [newSuivi, setNewSuivi] = useState<number>(initialSuivi)

  const [initialCancelInfo, setInitialCancelInfo] = useState<string>(props.data?.cancelInfo)

  const [openValidationModal, setOpenValidationModal] = useState<boolean>(false)

  const [comment, setComment] = useState<MessageState>({})
  const [commentUserInformation, setCommentUserInformation] = useState<any>({})
  const [message, setMessage] = useState<MessageState>({})

  const [error, setError] = useState<String>('')
  const orderType = [f('para'), f('ordo'), f('paraAndOrdo')]
  const [IsSidebar, setIsSideBar] = useState(false)

  const motifs = [f('outStock'), f('prescriptionProblem'), f('noHealthCard')]

  const pharmacyState = useSelector((state: RootState) => state.user.selectedPharma)

  // Collapse
  const [isChatOpen, setIsChatOpen] = useState(false)
  const toggleChat = (): void => {
    setIsChatOpen(!isChatOpen)
    setIsCommOpen(false)
    // updateNotifyMessages(false);
  }

  const [isCommOpen, setIsCommOpen] = useState(false)
  const toggleComm = (): void => {
    setIsCommOpen(!isCommOpen)
    setIsChatOpen(false)
    // updateNotifyCommentatries(false);
  }

  const detailsModal = (): JSX.Element => {
    if (props.data.type === 0) return <ModalProduits products={props.data.products} />
    if (props.data.type === 1) return <ModalOrdonnance data={props.data} getData={props.getData} />

    return <ModalCarousel products={props.data.products} />
  }

  const expeditionSentence = (): JSX.Element => {
    switch (props.data.deliveryMode) {
      case 'Expédition J+1':
        if (props.data.type === 1) {
          return <StyledDiv>{f('expeJ1Ordo')}</StyledDiv>
        } else {
          return <StyledDiv>{f('expeJ1')}</StyledDiv>
        }
      case 'clickAndCollect':
        return <StyledDiv>{f('cNc')}</StyledDiv>
      case 'express 2h':
        return <StyledDiv>{f('express')}</StyledDiv>
      case 'express 3h':
        return <StyledDiv>{f('express')}</StyledDiv>
      case 'horaire':
        return <StyledDiv>{f('horaire')}</StyledDiv>
      default:
        return <StyledDiv>{f('horaire')}</StyledDiv>
    }
  }

  const priceModal = (): JSX.Element =>
    <ModalOrdonnancePrice
      price={props.data.costsDetails}
      prescriptionId={props.data.prescriptionId}
      getData={props.getData}
      setPrice={setNewPrice}
    />

  const infoChangeNoloading = (infoToChange: string, newValue: number | string, motif?: string): void => {
    const index = props.filteredRows.map((el: any) => el.orderId).indexOf(props.data.orderId)
    props.filteredRows[index][infoToChange] = newValue

    if (motif !== null) {
      props.filteredRows[index].cancelInfo = motif
    }

    if (infoToChange === 'costsDetails') {
      const regex = /^\d+[.|,]\d+|^\d+$/g
      let newRemainingCost = 0
      const regexMatch = newValue?.toString()?.match(regex)

      if (newValue !== '' && regexMatch != null) {
        const price = props.filteredRows[index][infoToChange].replace(',', '.')
        newRemainingCost = parseFloat(price)
      }
      props.filteredRows[index][infoToChange] = newRemainingCost
    }
  }

  const confirmChanges = (): void => {
    if (initialCommentary !== newCommentary) {
      editCommentary()
    }

    if (initialStatut !== selectedStatut || initialCancelInfo !== cancelInfo) {
      props.displayFaCircle()
      editStatut(selectedStatut).catch(err => console.log(err))
    }

    if (initialPrice !== newPrice) {
      props.setSelectOrderId(props.data.orderId)
      props.displayFaCircle()
      editPrice()
    }
    if (initialSuivi !== newSuivi) {
      editSuivi()
    }
    closeAndReset()
  }

  const editPrice = (): void => {
    API.post('/prescription/finalPrice', {
      prescriptionId: props.data.prescriptionId,
      price: newPrice
    })
      .then((res) => {
        alertSuccess(f('editPriceSuccess'))
        infoChangeNoloading('costsDetails', newPrice, cancelInfo)
      })
      .catch((err) => {
        console.log('err: ', err)
        alertError(f('editPriceFailed'))
      })
  }

  const editSuivi = (): void => {
    API.post('/pharmacist/carts/suivi', {
      orderId: props.data.orderId,
      pharmacyOrderId: props.data.pharmacyOrderId,
      type: props.data.type,
      suivi: newSuivi
    })
      .then((res) => {
        alertSuccess(f('editMonitoringSuccess'))
        sendReloadInfo(props.data.orderId, props.data.pharmacyOrderId)
        props.getData()
      })
      .catch((err) => {
        console.log('err: ', err)
        alertError(f('editMonitoringFailed'))
      })
  }

  const editStatut = async (statut: number): Promise<void> => {
    if (statut >= 6 && cancelInfo === '') alertError(f('reasonCancel'))

    const body: { [key: string]: any } = {
      orderId: props.data.orderId,
      pharmacyOrderId: props.data.pharmacyOrderId,
      state: statut,
      type: props.data.type,
      pharmacyName: props.data.pharmacyName,
      origin: 'pharmacist',
      oldState: initialStatut
    }

    if (statut >= 6) body.cancel_info = cancelInfo

    try {
      const res = await API.put('/pharmacist/carts/state', body)

      if (res.data.success === true) {
        if (selectedStatut === 2) {
          if (
            props.data.deliveryMode === 'express 2h' ||
            props.data.deliveryMode === 'express 3h' ||
            props.data.deliveryMode === 'horaire'
          ) {
            alertSuccess(f('express'))
          } else if (props.data.deliveryMode === 'clickAndCollect') {
            alertSuccess(f('cNc'))
          } else {
            if (props.data.type === 1) {
              alertSuccess(f('expeJ1Ordo'))
            } else {
              alertSuccess(f('expeJ1'))
            }
          }
        }
        infoChangeNoloading('state', statut, cancelInfo)
      }
    } catch (e: any) {
      console.log('err : ', e)
      setResultChgmtStatut(f('editStatusFailed'))
    }
  }

  const editCommentary = (): void => {
    const data = {
      orderId: props.data.orderId,
      pharmacyOrderId: props.data.pharmacyOrderId,
      commentary: newCommentary,
      type: props.data.type
    }

    API.post('/pharmacist/carts/commentary', data)
      .then((res) => {
        setNewCommentary('')
      })
      .catch((err) => {
        console.log('err: ', err)
      })
  }

  // Closes Modal, disables Button & resets Message
  const closeAndReset = (): void => {
    setResultChgmtStatut('')
    setOpenValidationModal(false)
    setNewCommentary('')
    props.onClose()
  }

  useEffect(() => {
    setInitialStatut(props.data.state)
    setSelectedStatut(props.data.state)

    setInitialCommentary(props.data.commentaryPharmacist)
    setNewCommentary(props.data.commentaryPharmacist)

    setInitialPrice(props.data.costsDetails)
    setNewPrice(props.data.costsDetails)

    setIsChatOpen(false)
    setIsCommOpen(false)

    setInitialCancelInfo(props.data.cancelInfo)

    setCancelInfo(props.data.cancelInfo != null ? props.data.cancelInfo : '')

    getComment()
    getMessage()
  }, [props.data])

  useEffect(() => {
    props.setIconCircle('faCircleNotch')
    props.setSelectOrderId(-1)
    getComment()
    getMessage()
  }, [props.open])

  const getComment = (): void => {
    API.get(`/chat/commentaries/${props.data.orderId}t${props.data.pharmacyOrderId}`)
      .then((res) => {
        if (res.data.result != null) {
          setComment(res.data.result)
          setCommentUserInformation(res.data.userInformation)
        } else if (res.data.userInformation != null) {
          setCommentUserInformation(res.data.userInformation)
        } else {
          setComment({})
        }
      })
      .catch((err) => {
        console.log('err: ', err)
        alertError(f('loadComFailed'))
      })
  }

  const getMessage = (): void => {
    API.get(`/chat/messages/${props.data.orderId}t${props.data.pharmacyOrderId}`)
      .then((res) => {
        if (res.data.result != null) {
          setMessage(res.data.result)
        } else {
          setMessage({})
        }
      })
      .catch((err) => {
        console.log('err: ', err)
        alertError(f('loadMessageFailed'))
      })
  }

  const handleCancel = (event: any): void => {
    setError('')
    switch (event.target.value) {
      case '0':
        setCustomCancelInfo(false)
        setCancelInfo('')
        break
      case '1':
        setCustomCancelInfo(false)
        setCancelInfo(f('outStock'))
        break
      case '2':
        setCustomCancelInfo(false)
        setCancelInfo(f('prescriptionProblem'))
        break
      case '3':
        setCustomCancelInfo(false)
        setCancelInfo(f('noHealthCard'))
        break
      case '4':
        setCancelInfo('')
        setCustomCancelInfo(true)
        break
      case '5':
        setCancelInfo(f('noHealthDoc'))
        setCustomCancelInfo(false)
        break
      case '6':
        setCancelInfo(f('riskOfFraud'))
        setCustomCancelInfo(false)
        break
      case '7':
        setCancelInfo(f('prescriptionMoreSecure'))
        setCustomCancelInfo(false)
        break
      case '8':
        setCancelInfo(f('noAvailableProduct'))
        setCustomCancelInfo(false)
        break
      default:
        setCancelInfo('')
        setCustomCancelInfo(false)
    }
  }

  const handleCancelInfo = (event: any): void => {
    setCancelInfo(event.target.value)
  }

  const getIfSelected = (selectedCancelInfo: string): boolean => {
    let isSelected = false
    if (props.data.cancelInfo != null) {
      if (props.data.cancelInfo === selectedCancelInfo) {
        isSelected = true
      } else if (selectedCancelInfo === f('other') && !motifs.includes(props.data.cancelInfo)) {
        isSelected = true
      }
    }

    return isSelected
  }

  const bodyOrdo = (): JSX.Element => {
    return (
      <StyledModalBody>
        <h3>
          {f('orderPhacilNb')}
          {props.data.orderId}
        </h3>
        {props.data.origin && (
          <OriginBadge>
            {f('origin')}{props.data.origin}
          </OriginBadge>
        )}
        {
          props.data.type === 1 &&
          <Row>
            <Col className='info'>
              <FontAwesomeIcon icon={faInfo} color='#202855' onClick={() => { setIsSideBar(!IsSidebar) }} />
            </Col>
          </Row>
        }

        {props.data.recurrent === 'true' && (
          <Row>
            <Col className='InfoRecurrent'>
              <FontAwesomeIcon icon={faRedoAlt} />
              <span>{f('recuOrdo')}</span>
            </Col>
          </Row>
        )}
        <p className='textModal'>
          {f('orderPharmaNb')}
          {props.data.pharmacyOrderId}
        </p>
        <p className='textModal'>
          {f('client')}
          {props.data.customerName}
        </p>
        <p className='textModal'>
          {f('phone')} : {props.data.customerPhone}
        </p>
        <p className='textModal'>
          {f('deliveryAddress')}
          {props.data.deliveryAddress}
        </p>
        <p className='textModal'>
          {f('deliveryAddress2')}{' '}
          {props.data.deliveryAddress2 && props.data.deliveryAddress2 !== ''
            ? props.data.deliveryAddress2
            : f('notGiven')}
        </p>
        <p className='textModal'>{f('Mode')} {props.data.deliveryMode}</p>
        <p className='textModal'>
          {f('delivery')}{' '}
          {moment.parseZone(props.data.deliveryScheduleStart).format('ddd D MMM') + f('between')}
          {moment.parseZone(props.data.deliveryScheduleStart).format('HH:mm') + f('and')}
          {moment.parseZone(props.data.deliveryScheduleEnd).format('HH:mm')}
        </p>
        <p className='textModal'>
          {f('orderType')}
          {orderType[props.data.type]}
        </p>
        <br />
        {props.data.type === 1 && priceModal()}
        {props.data.deliveryMode === 'Expédition J+1' && (
          <TntDiv>
            <h3>
              Gestion bon TNT
            </h3>

            {
              pharmacyState.pharmacy.tnt_delivery_form === 'manual' || pharmacyState.pharmacy.tnt_delivery_form == null
                ? (
                  <>
                    <AskTntFile data={props.data} />
                    <DownloadTntFile data={props.data} />
                  </>

                )
                : (
                  <DownloadTntFile data={props.data} />
                )
            }
          </TntDiv>

        )}
        <br />
        <Row>
          <Col>
            <StyledFormGroup>
              <div className='editStatutWrapper'>
                <StyledLabel for='statutCmd'>
                  <b>{f('editStatus')}</b>
                </StyledLabel>
                <StyledInput
                  type='select'
                  name='select'
                  id='statutCmd'
                  onChange={(e: any) => {
                    setSelectedStatut(parseInt(e.target.value))
                    setResultChgmtStatut('')
                    setError('')
                  }}
                >
                  <option selected={props.data?.state === 0 || props.data?.state === 1} value='1'>
                    {f('waitingConfirm')}
                  </option>
                  <option selected={props.data?.state > 1 && props.data?.state < 6} value='2'>
                    {f('validated')}
                  </option>
                  <option selected={props.data?.state >= 6} value='6'>
                    {f('canceled')}
                  </option>
                </StyledInput>
              </div>
            </StyledFormGroup>
            {resultChgmtStatut !== '' &&
              (resultChgmtStatut === 'Changement de statut effectué avec succès' ? (
                <p className='successMessage'>{resultChgmtStatut}</p>
              ) : (
                <p className='errorMessage'>{resultChgmtStatut}</p>
              ))}
          </Col>
        </Row>
        {selectedStatut >= 6 && (
          <Row>
            <Col>
              <StyledFormGroup>
                <div className='editStatutWrapper'>
                  <StyledLabel for='cancelReason'>{f('cancelMotifLabel')}</StyledLabel>
                  <StyledInput
                    type='select'
                    name='reason'
                    id='cancelReason'
                    onChange={handleCancel}
                  >
                    <option value='0' />
                    {
                      props.data.prescriptionId != null
                        ? <>
                          <option selected={getIfSelected(f('noHealthDoc'))} value='5'>{f('noHealthDoc')}</option>
                          <option selected={getIfSelected(f('riskOfFraud'))} value='6'>{f('riskOfFraud')}</option>
                          <option selected={getIfSelected(f('prescriptionMoreSecure'))} value='7'>{f('prescriptionMoreSecure')}</option>
                          <option selected={getIfSelected(f('noAvailableProduct'))} value='8'>{f('noAvailableProduct')}</option>

                        </>
                        : <>
                          <option selected={getIfSelected(f('outStock'))} value='1'>{f('outStock')}</option>
                          <option selected={getIfSelected(f('prescriptionProblem'))} value='2'>{f('prescriptionProblem')}</option>
                          <option selected={getIfSelected(f('noHealthCard'))} value='3'>{f('noHealthCard')}</option>
                          <option selected={getIfSelected(f('other'))} value='4'>{f('other')}</option>
                        </>
                    }

                  </StyledInput>
                </div>
              </StyledFormGroup>
            </Col>
          </Row>
        )}
        {selectedStatut >= 6 && customCancelInfo && (
          <Row>
            <Col>
              <Input
                type='text'
                onChange={handleCancelInfo}
                placeholder={f('cancelReason')}
                id='customCancelInfo'
                style={{ width: '100%', margin: '0 auto' }}
              />
            </Col>
          </Row>
        )}
        <p className='error'>{error}</p>
        <Row>
          <Col>{selectedStatut > 1 && selectedStatut < 6 && expeditionSentence()}</Col>
        </Row>
        <br />
        <CenteredRow>
          <CustomButton
            primary
            onClick={() => {
              selectedStatut >= 6 && cancelInfo === ''
                ? setError(f('cancelMotif'))
                : confirmChanges()
              sendReloadInfo(props.data.orderId, props.data.orderId)
            }}
          // disabled={selectedStatut >= 6 && cancelInfo === ''}
          >
            {f('validChanges')}
          </CustomButton>
        </CenteredRow>
        <hr />
        <StyledRow>
          <Col>
            <CustomButton onClick={toggleComm}>
              {Object.keys(comment).length !== 0 && comment.constructor === Object && (
                <ColoredFontAwesome icon={faExclamationCircle} />
              )}{' '}
              {f('commentSection')}
              <FontAwesomeIcon icon={isCommOpen ? faMinus : faPlus} />
            </CustomButton>
          </Col>
          <Col>
            <CustomButton style={{ cursor: 'pointer' }} onClick={toggleChat}>
              {Object.keys(message).length !== 0 && message.constructor === Object && (
                <ColoredFontAwesome icon={faExclamationCircle} />
              )}{' '}
              {f('messageSection')}
              <FontAwesomeIcon icon={isChatOpen ? faMinus : faPlus} />
            </CustomButton>
          </Col>
        </StyledRow>
        <Collapse isOpen={isCommOpen}>
          <CommentaryChat
            orderId={props.data.orderId}
            pharmacyOrderId={props.data.pharmacyOrderId}
            messageList={comment}
            userInformation={commentUserInformation}
            pharmacyName={props.data.pharmacyName}
            pharmacyId={props.data.pharmacyId}
            type={props.data.type}
          />
        </Collapse>
        <Collapse isOpen={isChatOpen}>
          <Chat
            orderId={props.data.orderId}
            pharmacyOrderId={props.data.pharmacyOrderId}
            pharmacyName={props.data.pharmacyName}
            user_email={props.data.user_email}
            type={props.data.type}
          />
        </Collapse>
        <hr />
        {detailsModal()}
      </StyledModalBody>
    )
  }

  return (
    <div style={{ display: 'flex' }}>
      <ModalWrapper
        IsSidebar={IsSidebar}
        isOpen={props.open}
        toggle={() => {
          if (
            initialCommentary !== newCommentary ||
            initialStatut !== selectedStatut ||
            initialPrice !== newPrice
          ) {
            setOpenValidationModal(true)
          } else {
            closeAndReset()
          }
        }}
      >
        <ValidationModal
          open={openValidationModal}
          confirm={confirmChanges}
          cancel={() => {
            setNewCommentary(initialCommentary)
            closeAndReset()
          }}
        />
        {
          IsSidebar ? (
            <Row>
              <Col md={IsSidebar ? '7' : '12'}>
                {bodyOrdo()}
              </Col>
              {
                IsSidebar &&
                <Col md='5'>
                  <SidebarOrdonnance />
                </Col>
              }
            </Row>
          )
            : (
              <Row>
                <Col>
                  {bodyOrdo()}
                </Col>
              </Row>
            )
        }
      </ModalWrapper>
    </div>
  )
}

const ColoredFontAwesome = styled(FontAwesomeIcon)`
  color: #6c757d;
`

const StyledRow = styled(Row)`
  margin-bottom: 10px;
  margin-top: 2rem;
`

const CenteredRow = styled(Row)`
  justify-content: center;
  margin-bottom: 2rem;
`

const StyledFormGroup = styled(FormGroup)`
  width: -moz-available;
`

const StyledLabel = styled(Label)`
  display: flex;
  justify-content: center;
`

const StyledInput = styled(Input)`
  width: 50%;
  margin-left: 4%;
`

const StyledDiv = styled(Card)`
  color: black;
  background-color: #d9d9d9;
  padding: 10px;
  margin-bottom: 5px;
  border: None;
`

const StyledModalBody = styled(ModalBody)`
  .InfoRecurrent {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    border-right: 1px solid #606063;
    border-left: 1px solid #606063;
    padding: 0.5rem;
    background-color: #36b501;
  }
  .InfoRecurrent svg,
  .InfoRecurrent span {
    color: white;
  }
  .InfoRecurrent svg {
    width: 1rem;
    margin-right: 0.5rem;
  }
  .InfoRecurrent span {
    font-weight: bold;
  }
  .info  {
    float: right;
    position: absolute
  }
  .info  svg {
    float: right;

  }

`

const ModalWrapper = styled(Modal)`
.modal-content {
  width: ${props => props.IsSidebar === true ? '170%' : '100%'};
  // transform: ${props => props.IsSidebar === true ? 'translate(-50%, -50%)' : ''};
  // position: ${props => props.IsSidebar === true ? 'absolute' : ''};
  // float: ${props => props.IsSidebar === true ? 'left' : ''};
  // left: ${props => props.IsSidebar === true ? '50%' : ''};
  // top: ${props => props.IsSidebar === true ? '50%' : ''};
  // bottom: ${props => props.IsSidebar === true ? '50%' : ''};

}
h3 {
    font-weight: bold;
    font-size: 1.2rem;
    color: #202855;
    text-align: center;
  }
  h4 {
    font-weight: bold;
    font-size: 1rem;
    color: #202855;
    text-align: center;
  }
  .textModal {
    margin: 0;
    font-weight: bold;
    font-size: 0.9rem;
    color: #6c6f7d;
  }
  .card-group {
    width: 100%;
  }
  .bold-p {
    font-weight: bold;
    color: black;
    font-size: 1rem;
  }

  .editStatutWrapper {
    margin-top: 0.5rem;
    display: flex;
    align-items: center;
    select {
      margin-right: 1rem;
    }
  }
  p.successMessage {
    color: #28a745;
    margin-bottom: 0;
  }
  p.errorMessage {
    color: red;
    margin-bottom: 0;
  }
  .error{
    color: red;
    margin-top: 1rem;
    text-align: center
  }
`

const TntDiv = styled.div`
width: 11rem;
h3 {
  text-align: unset !important
}
`

const OriginBadge = styled.div`
  text-align: center;
  font-size: 1.1rem;
  background: rgb(217, 217, 217);
  margin: 0 25%;
  border-radius: 2px;
  padding: 5px;
  margin-bottom: 15px;
  font-weight: bold;
  color: rgb(108, 111, 125);
`

export default OrderModal
