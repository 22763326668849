import { Column, TableProps } from 'models/table.model'
import React from 'react'
import {
  AlignCenter as SortIcon,
  ChevronDown as SortDescIcon,
  ChevronUp as SortAscIcon
} from 'react-feather'

const HeaderTable: React.FC<TableProps> = ({ columns, sortCriteria, sortChangedHandler, setSortCriteria, thEqual, thAccessor, thClassname, sortDescOrAsc, parent }) => {
  return (
    <>
      {columns.map((column: Column, index: number) => {
        let icon = <SortIcon />
        const sortAccessor = column.sortAccessor || column.accessor
        if ((sortCriteria != null) && sortCriteria.accessor === sortAccessor) {
          icon =
            sortCriteria.direction === 'DESC' ? (
              <SortDescIcon />
            ) : (
              <SortAscIcon />
            )
        }

        let classname = ''
        if (thEqual === true) classname = thClassname != null && column.accessor === thAccessor ? thClassname : ''
        else classname = thClassname != null && column.accessor !== thAccessor ? thClassname : ''

        return (
          <th
            key={`head_table_${index}`}
            style={{ width: parent === 'dashboardKPIs' ? '50%' : '' }}
            onClick={() => {
              // Use sortAccessor property if defined, otherwise fall back to accessor
              if (column.sortable || column.filtrable != null) {
                const sortAccessor = column.sortAccessor || column.accessor
                sortChangedHandler(sortAccessor, sortDescOrAsc, setSortCriteria, sortCriteria)
              }
            }}
            className={classname}
          // className={index === 0 ? thClassname : ''}
          >
            <div>
              <span>{column.Header}</span>
              {(column.filtrable != null || column.sortable) ? icon : null}
            </div>
          </th>
        )
      })}
    </>
  )
}

export default HeaderTable
