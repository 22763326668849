/**
 * if a sortCriteria is defined, sort data rows according to it
 * @fires setData
 */

export interface SortCriteria {
  direction: 'DESC' | 'ASC'
  accessor: string
}

export const applySort = (
  data: unknown[],
  setData: React.Dispatch<React.SetStateAction<any>>,
  sortCriteria: SortCriteria | null
): void => {
  interface SortItem {
    [index: string]: number | string
  }
  if (sortCriteria == null) return
  const sortAsc = (accessor: string, a: SortItem, b: SortItem) => {
    // Special handling for moment objects (dateOrder)
    if (accessor === 'dateOrder') {
      const dateA = a[accessor] as any
      const dateB = b[accessor] as any
      if (dateA.valueOf() < dateB.valueOf()) return -1
      return 1
    }
    
    // Standard comparison for other types
    if (a[accessor] < b[accessor]) return -1
    return 1
  }
  const sortDesc = (accessor: string, a: SortItem, b: SortItem) => {
    // Special handling for moment objects (dateOrder)
    if (accessor === 'dateOrder') {
      const dateA = a[accessor] as any
      const dateB = b[accessor] as any
      if (dateA.valueOf() > dateB.valueOf()) return -1
      return 1
    }
    
    // Standard comparison for other types
    if (a[accessor] > b[accessor]) return -1
    return 1
  }
  const sortFunction = sortCriteria.direction === 'DESC' ? sortDesc : sortAsc
  const sortedData = [...data].sort((a, b) => {
    const a1 = a
    const a2 = a1 as SortItem
    const b1 = b
    const b2 = b1 as SortItem
    return sortFunction(sortCriteria.accessor, a2, b2)
  })
  setData([...sortedData])
}

export const sortChangedHandler = (
  columnAccessor: string,
  direction: 'DESC' | 'ASC',
  setSortCriteria: React.Dispatch<React.SetStateAction<SortCriteria | null>>,
  sortCriteria: SortCriteria | null
): void => {
  if (sortCriteria == null || sortCriteria.accessor !== columnAccessor) {
    setSortCriteria({
      accessor: columnAccessor,
      direction
    })
    return
  }
  setSortCriteria({
    accessor: columnAccessor,
    direction: sortCriteria.direction === 'DESC' ? 'ASC' : 'DESC'
  })
}
